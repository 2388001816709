<template>
	<TnDivider
		:size="content.size || 'l'"
		:width="content.width || 'wide'"
	/>
</template>

<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "HorizontalRuler",
	mixins: [GizmoComponentMixin],
});
</script>
